<template>
  <div>
    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      :offset="10"
      finished-text="没有更多了"
      @load="loadData"
    >
      <div v-for="(item, index) in dataList" :key="index" class="store-item">
        <div class="store-item_merchant">
          <div class="merchant-title">
            <img src="~assets/images/my/account1.png" />
            <div class="merchant-name">{{ item.merchantStoreName || "" }}</div>
          </div>
          <div class="merchant-edit" @click="onEdit(item)">编辑门店</div>
        </div>
        <div class="merchant-account">门店账号：{{ item.account || "" }}</div>
        <div class="merchant-statistics_wrap">
          <div class="merchant-statistics_item">
            <div class="text-value">
              {{ (item.todayTransactionAmount || 0).toFixed(2) }}
            </div>
            <div class="text-label">今日交易金额</div>
          </div>
          <div class="merchant-statistics_item">
            <div class="text-value">
              {{ (item.todayTransactionNum || 0).toFixed(2) }}
            </div>
            <div class="text-label">今日交易笔数</div>
          </div>
          <div class="merchant-statistics_item">
            <div class="text-value">
              {{ (item.monthTransactionAmount || 0).toFixed(2) }}
            </div>
            <div class="text-label">本月交易金额</div>
          </div>
          <div class="merchant-statistics_item">
            <div class="text-value">
              {{ (item.monthTransactionNum || 0).toFixed(2) }}
            </div>
            <div class="text-label">本月交易笔数</div>
          </div>
        </div>
      </div>
    </van-list>
    <div class="bottom-box">
      <van-button round block class="submit-btn" @click="onAdd">+新增门店</van-button>
    </div>
  </div>
</template>
<script>
import { getStoreStatisticsList } from "api/common";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      formData: {
        page: 1,
        pageSize: 10
      },
      dataList: []
    };
  },
  created() {
    this.loadStoreList();
  },
  methods: {
    loadData() {
      this.formData.page++;
      this.loadStoreList();
    },
    loadStoreList() {
      getStoreStatisticsList(this.formData).then(res => {
        console.log("getStoreStatisticsList", res);
        this.loading = false
        const { totalCount, list } = res.data
        if (this.formData.page === 1) {
          this.dataList = list;
        } else {
          this.dataList = this.dataList.concat(list);
        }
         
        this.finished = list.length >= totalCount;
      }).catch(res => {
        this.loading = false
        this.finished = true;
      });
    },
    onAdd() {
      this.$router.push('/store/add')
    },
    onEdit(item) {
      this.$router.push(`/store/edit?id=${item.merchantStoreId}`)
    }
  }
};
</script>

<style lang="less" scoped>
.store-item {
  background: #ffffff;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 10px 0 10px;

  .store-item_merchant {
    display: flex;
    align-items: center;

    .merchant-title {
      display: flex;
      flex: 1;
      align-items: center;

      img {
        width: 24px;
        height: 22px;
      }
    }

    .merchant-name {
      margin-left: 10px;
      font-size: 15px;
      line-height: 15px;
      color: #333333;
    }

    .merchant-edit {
      flex-shrink: 0;
      background: linear-gradient(90deg, #ff9241 4%, #fd6e05);
      border-radius: 11px;
      padding: 6px 15px;
      font-size: 10px;
      color: #fff;
    }
  }

  .merchant-account {
    font-size: 13px;
    color: #999;
    margin-top: 16px;
  }

  .merchant-statistics_wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: flex-start;
    grid-gap: 10px;
    margin-top: 10px;
  }

  .merchant-statistics_item {
    background: #f8f8f8;
    border-radius: 5px;
    padding: 10px;
    text-align: center;

    .text-value {
      font-size: 14px;
      color: #333;
    }

    .text-label {
      margin-top: 8px;
      font-size: 11px;
      color: #999;
    }
  }
}
</style>
